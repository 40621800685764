.color-picker-container {
    position: absolute;
    /* align-content: center; */
    right: 35%;
    /* left: 50%; */
    /* top: 20% */
    z-index: 1000;
}


.text-circle-color {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 574px)  {
    .text-circle-color {
        width: 50px;
        height: 50px;
        font-size: 0.75rem;
    }
}